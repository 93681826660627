import React, { FC } from 'react'
import Svg, { Path, SvgProps } from 'react-native-svg'

const FluentBuilding: FC<{ fillColor?: string } & SvgProps> = ({
  fillColor = '#212121',
  ...props
}) => (
  <Svg
    width={24}
    height={24}
    fill='none'
    viewBox='0 0 24 24'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <Path
      d='M8.5 5.5a1 1 0 100 2 1 1 0 000-2zm-1 8a1 1 0 112 0 1 1 0 01-2 0zm1-4.5a1 1 0 100 2 1 1 0 000-2zM11 6.5a1 1 0 112 0 1 1 0 01-2 0zm1 6a1 1 0 100 2 1 1 0 000-2zm2.5 1a1 1 0 112 0 1 1 0 01-2 0zM12 9a1 1 0 100 2 1 1 0 000-2z'
      fill={fillColor}
    />
    <Path
      d='M6.25 2A2.25 2.25 0 004 4.25v16.5c0 .414.336.75.75.75h14.503a.75.75 0 00.75-.75v-9a2.25 2.25 0 00-2.25-2.25H16.5V4.25A2.25 2.25 0 0014.25 2h-8zM5.5 4.25a.75.75 0 01.75-.75h8a.75.75 0 01.75.75v6c0 .414.336.75.75.75h2.003a.75.75 0 01.75.75V20H16.5v-2.75a.75.75 0 00-.75-.75h-7.5a.75.75 0 00-.75.75V20h-2V4.25zM15 18v2h-2.25v-2H15zm-3.75 0v2H9v-2h2.25z'
      fill={fillColor}
    />
  </Svg>
)
export default FluentBuilding
